h1 {
  font-weight: 500;
  font-size: 31px;
  line-height: 37.2px;
  letter-spacing: 0.5%;
  color: #000;
  margin-top: 10px;
}

h2 {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: #000;
  margin-bottom: 16px;
  margin-top: 22px;
}

p,
ul,
li {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #11142d;
  margin: 0;
}
