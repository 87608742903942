h4 {
  font-family: 'SF Pro Display', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.38px;
  color: #3d3835;
  margin-top: 12px;
  margin-bottom: 12px;
}

.privacy {
  font-family: 'SF Pro Display', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #3d3835;
}

.mb16 {
  margin-bottom: 16px;
}
